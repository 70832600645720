export default [
  'default',
  'boat',
  'boomlift',
  'bus',
  'car',
  'cat',
  'crane',
  'dog',
  'drone',
  'firetruck',
  'footruck',
  'motorcycle',
  'person',
  'pickup',
  'schoolvan',
  'securitycar',
  'skyjack',
  'suv',
  'tractor',
  'train',
  'truck',
  'van',
];
